import React, { useState, useEffect } from 'react';

const Dashboard = () => {
    const [heartbeatData, setHeartbeatData] = useState(null);
    const [cartsData, setCartsData] = useState([]);

    useEffect(() => {
        // Fetch heartbeat data
        fetch('https://backend.danilodash.com/api/heartbeat/')
            .then(response => response.json())
            .then(data => setHeartbeatData(data));

        // Fetch carts data
        fetch('https://backend.danilodash.com/api/carts/')
            .then(response => response.json())
            .then(data => setCartsData(data));
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('it-IT', options);
    };

    const BarChart = ({ data }) => {
        const maxValue = Math.max(...data.flatMap(item => [item.unici, item.totali]));

        return (
            <div style={{ display: 'flex', justifyContent: 'space-around', height: '300px', alignItems: 'flex-end' }}>
                {data.map((item, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column',height: '100%', alignItems: 'center' }}>
                        <div style={{ display: 'flex', height: '100%', alignItems: 'flex-end' }}>
                            <div style={{ width: '30px', height: `${parseInt(item.unici / maxValue * 100)}%`, backgroundColor: '#8884d8', marginRight: '5px' }} />
                            <div style={{ width: '30px', height: `${parseInt(item.totali / maxValue * 100)}%`, backgroundColor: '#82ca9d' }} />
                        </div>
                        <div>{item.unici}&nbsp;&nbsp;/&nbsp;&nbsp;{item.totali}</div>
                        <div>{item.name}</div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Dashboard Utenti e Carrelli</h1>

            {heartbeatData && (
                <div style={{ marginBottom: '30px', border: '1px solid #ddd', borderRadius: '8px', padding: '20px' }}>
                    <h2 style={{ fontSize: '20px', fontWeight: 'semibold', marginBottom: '15px' }}>Statistiche Utenti</h2>
                    <BarChart data={[
                        { name: 'Giornalieri', unici: heartbeatData.daily.unique, totali: heartbeatData.daily.total },
                        { name: 'Settimanali', unici: heartbeatData.weekly.unique, totali: heartbeatData.weekly.total },
                        { name: 'Mensili', unici: heartbeatData.monthly.unique, totali: heartbeatData.monthly.total },
                        { name: 'Totali', unici: heartbeatData.total.unique, totali: heartbeatData.total.total },
                    ]} />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                            <div style={{ width: '20px', height: '20px', backgroundColor: '#8884d8', marginRight: '5px' }} />
                            <span>Utenti Unici</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '20px', height: '20px', backgroundColor: '#82ca9d', marginRight: '5px' }} />
                            <span>Visite Totali</span>
                        </div>
                    </div>
                </div>
            )}

            <div style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '20px' }}>
                <h2 style={{ fontSize: '20px', fontWeight: 'semibold', marginBottom: '15px' }}>Dettagli Carrelli</h2>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                    <tr style={{ borderBottom: '2px solid #ddd' }}>
                        <th style={{ padding: '10px', textAlign: 'left' }}>Data</th>
                        <th style={{ padding: '10px', textAlign: 'left' }}>Oggetti</th>
                        <th style={{ padding: '10px', textAlign: 'left' }}>Prezzo Totale</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cartsData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((cart) => (
                        <tr key={cart._id} style={{ borderBottom: '1px solid #ddd' }}>
                            <td style={{ padding: '10px' }}>{formatDate(cart.createdAt)}</td>
                            <td style={{ padding: '10px' }}>
                                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                    {cart.cartData.items.map((item, index) => (
                                        <li key={index}>{item.title} (x{item.quantity})</li>
                                    ))}
                                </ul>
                            </td>
                            <td style={{ padding: '10px' }}>{(cart.cartData.total / 100).toFixed(2)} €</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dashboard;