import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const BASE_URL = 'https://danilodash.com';

 // Importa gli stili CSS di Quill

const token = localStorage.getItem('authToken');

const PostForm = () => {
    const quillRef = React.useRef();
    const [post, setPost] = useState({
        handle: '',
        title: '',
        author: '',
        published: false,
        content: '',
        description: '',
        keywords: [],
        category_id: '',
        tag_ids: [],
        product_ids: [],
        collection_ids: [],
        metadata: {}
    });
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [categoriesRes, tagsRes, productsRes] = await Promise.all([
                    fetch(`${BASE_URL}/store/blog/categories`).then(res => res.json()),
                    fetch(`${BASE_URL}/store/blog/tags`).then(res => res.json()),
                    fetch(`${BASE_URL}/store/products?limit=200`).then(res => res.json())
                ]);

                setCategories(categoriesRes.map(cat => ({ value: cat.id, label: cat.title })));
                setTags(tagsRes.map(tag => ({ value: tag.id, label: tag.value })));
                setProducts(productsRes.products.map(prod => ({ value: prod.id, label: prod.title })));

                if (id) {
                    const postRes = await fetch(`${BASE_URL}/store/blog/posts/${id}`);

                    const postData = await postRes.json();
                    postData.product_ids = postData.products?.map(prod => (prod.id));
                    setPost(postData);
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPost(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleArrayInputChange = (name, value) => {
        setPost(prev => ({
            ...prev,
            [name]: value.split(',').map(item => item.trim())
        }));
    };

    const handleSubmit = (e,postId) => {
        e.preventDefault();
        const method = id ? 'POST' : 'POST';
        const url = id ? `${BASE_URL}/admin/blog/posts/${postId}` : `${BASE_URL}/admin/blog/posts`;

        fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' ,'Authorization': `Bearer ${token}`},
            body: JSON.stringify(post)
        }).then(() => navigate('/admin/posts'));
    };

    const imageHandler = () => {
        const url = prompt('Enter the URL of the image:');
        if (url) {
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, 'image', url, 'user');
        }
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
            ],
            handlers: {
                image: imageHandler
            }
        },
    }),[]);

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];


    if (loading || !products) {
        return <div>Loading...</div>;
    }

    return (
        <div className="admin-container">
            <h2>{id ? 'Edit Post' : 'Create New Post'}</h2>
            <form onSubmit={e=>handleSubmit(e,post.id)}>
                <input
                    type="text"
                    name="handle"
                    value={post.handle}
                    onChange={handleInputChange}
                    placeholder="Handle"
                    required
                />
                <input
                    type="text"
                    name="title"
                    value={post.title}
                    onChange={handleInputChange}
                    placeholder="Title"
                    required
                />
                <input
                    type="text"
                    name="author"
                    value={post.author}
                    onChange={handleInputChange}
                    placeholder="Author"
                />
                <label>
                    <input
                        type="checkbox"
                        name="published"
                        checked={post.published}
                        onChange={handleInputChange}
                    /> Published
                </label>
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={post.content}
                    onChange={(content) => setPost(prev => ({ ...prev, content }))}
                    modules={modules}
                    // formats={formats}
                    placeholder="Write your content here..."
                />
                <textarea
                    name="description"
                    value={post.description}
                    onChange={handleInputChange}
                    placeholder="Description"
                />
                <input
                    type="text"
                    value={post.keywords.join(', ')}
                    onChange={(e) => handleArrayInputChange('keywords', e.target.value)}
                    placeholder="Keywords (comma-separated)"
                />
                <Select
                    options={categories}
                    value={categories.find(cat => cat.value === post.category_id)}
                    onChange={(selected) => setPost(prev => ({ ...prev, category_id: selected.value }))}
                    placeholder="Select Category"
                />
                <Select
                    isMulti
                    options={tags}
                    value={tags.filter(tag => post.tag_ids?.includes(tag.value))}
                    onChange={(selected) => setPost(prev => ({ ...prev, tag_ids: selected.map(s => s.value) }))}
                    placeholder="Select Tags"
                />
                <Select
                    isMulti
                    options={products}
                    value={products.filter(prod => [...(post.product_ids||[]),...(post.products?.map(p => p.id)||[])]?.includes(prod.value))}
                    onChange={(selected) => setPost(prev => ({ ...prev, product_ids: selected.map(s => s.value) }))}
                    placeholder="Select Products"
                />
                <input
                    type="text"
                    value={post.collection_ids?.join(', ')}
                    onChange={(e) => handleArrayInputChange('collection_ids', e.target.value)}
                    placeholder="Collection IDs (comma-separated)"
                />
                {console.log(post)}
                <MetadataField
                    metadata={post.metadata}
                    setMetadata={(newMetadata) => setPost(prev => ({ ...prev, metadata: newMetadata }))}
                />
                <button type="submit">{id ? 'Update' : 'Create'} Post</button>
            </form>
        </div>
    );
};

const MetadataField = ({ metadata, setMetadata }) => {
    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    console.log({metadata})
    const addMetadata = () => {
        if (key && value) {
            setMetadata({ ...metadata, [key]: value });
            setKey('');
            setValue('');
        }
    };

    const removeMetadata = (keyToRemove) => {
        const newMetadata = { ...metadata };
        delete newMetadata[keyToRemove];
        setMetadata(newMetadata);
    };

    return (
        <div className="metadata-field">
            <h3>Metadata</h3>
            {Object.entries(metadata).map(([k, v]) => (
                <div key={k} className="metadata-item">
                    <span>{k}: {v}</span>
                    <button type="button" onClick={() => removeMetadata(k)}>Remove</button>
                </div>
            ))}
            <input
                type="text"
                value={key}
                onChange={(e) => setKey(e.target.value)}
                placeholder="Key"
            />
            <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Value"
            />
            <button type="button" onClick={addMetadata}>Add Metadata</button>
        </div>
    );
};
const CategoryForm = () => {
    const [category, setCategory] = useState({
        handle: '',
        title: '',
        description: '',
        keywords: [],
        metadata: {}
    });
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            fetch(`${BASE_URL}/store/blog/categories/${id}`)
                .then(res => res.json())
                .then(data => setCategory(data));
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const method = id ? 'PUT' : 'POST';
        const url = id ? `${BASE_URL}/admin/blog/categories/${id}` : `${BASE_URL}/admin/blog/categories`;

        fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(category)
        }).then(() => navigate('/admin/categories'));
    };

    const handleArrayInput = (e, field) => {
        setCategory({...category, [field]: e.target.value.split(',').map(item => item.trim())});
    };

    return (
        <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <input
                type="text"
                value={category.handle}
                onChange={e => setCategory({...category, handle: e.target.value})}
                placeholder="Handle"
                required
            />
            <input
                type="text"
                value={category.title}
                onChange={e => setCategory({...category, title: e.target.value})}
                placeholder="Title"
                required
            />
            <textarea
                value={category.description}
                onChange={e => setCategory({...category, description: e.target.value})}
                placeholder="Description"
            />
            <input
                type="text"
                value={category.keywords.join(', ')}
                onChange={e => handleArrayInput(e, 'keywords')}
                placeholder="Keywords (comma-separated)"
            />
            <MetadataField
                metadata={category.metadata}
                setMetadata={(newMetadata) => setCategory({...category, metadata: newMetadata})}
            />
            <button type="submit">{id ? 'Update' : 'Create'} Category</button>
        </form>
    );
};


export { PostForm, CategoryForm };