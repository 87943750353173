import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useParams } from 'react-router-dom';
import {CategoryForm, PostForm} from "./components/forms";
import Data from "./components/data";

const BASE_URL = 'https://danilodash.com';
const Login = ({ setIsAuthenticated }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/admin/auth/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });


            const data = await response.json();
            localStorage.setItem('authToken', data.access_token);
            navigate('/admin');
        } catch (err) {
            console.log(err)
            setError('Invalid email or password');
        }
    };

    return (
        <div className="admin-container">
            <h2>Login</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

// Componente AdminHome
const AdminHome = () => (
    <div>
        <h1>Admin Dashboard</h1>
        <nav>
            <ul>
                <li><Link to="/admin/posts">Manage Posts</Link></li>
                <li><Link to="/admin/categories">Manage Categories</Link></li>
                <li><Link to="/admin/tags">Manage Tags</Link></li>
            </ul>
        </nav>
    </div>
);

// Componenti per i Post

const AdminPosts = () => {
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${BASE_URL}/store/blog/posts`)
            .then(res => res.json())
            .then(data => setPosts(data));
    }, []);

    const deletePost = (id) => {
        if (window.confirm('Are you sure you want to delete this post?')) {
            fetch(`${BASE_URL}/admin/blog/posts/${id}`, { method: 'DELETE' })
                .then(() => setPosts(posts.filter(post => post.id !== id)));
        }
    };

    return (
        <div>
            <h1>Manage Posts</h1>
            <button onClick={() => navigate('/admin/posts/new')}>Create New Post</button>
            <table>
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Author</th>
                    <th>Published</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {posts.map(post => (
                    <tr key={post.id}>
                        <td>{post.title}</td>
                        <td>{post.author}</td>
                        <td>{post.published ? 'Yes' : 'No'}</td>
                        <td>
                            <button onClick={() => navigate(`/admin/posts/edit/${post.handle}`)}>Edit</button>
                            <button onClick={() => deletePost(post.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};


const AdminCategories = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${BASE_URL}/store/blog/categories`)
            .then(res => res.json())
            .then(data => setCategories(data));
    }, []);

    const deleteCategory = (id) => {
        if (window.confirm('Are you sure you want to delete this category?')) {
            fetch(`${BASE_URL}/admin/blog/categories/${id}`, { method: 'DELETE' })
                .then(() => setCategories(categories.filter(category => category.id !== id)));
        }
    };

    return (
        <div>
            <h1>Manage Categories</h1>
            <button onClick={() => navigate('/admin/categories/new')}>Create New Category</button>
            <table>
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Handle</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {categories.map(category => (
                    <tr key={category.id}>
                        <td>{category.title}</td>
                        <td>{category.handle}</td>
                        <td>
                            <button onClick={() => navigate(`/admin/categories/edit/${category.id}`)}>Edit</button>
                            <button onClick={() => deleteCategory(category.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

// Componenti per i Tag

const AdminTags = () => {
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${BASE_URL}/store/blog/tags`)
            .then(res => res.json())
            .then(data => setTags(data));
    }, []);

    const deleteTag = (id) => {
        if (window.confirm('Are you sure you want to delete this tag?')) {
            fetch(`${BASE_URL}/admin/blog/tags/${id}`, { method: 'DELETE' })
                .then(() => setTags(tags.filter(tag => tag.id !== id)));
        }
    };

    return (
        <div>
            <h1>Manage Tags</h1>
            <button onClick={() => navigate('/admin/tags/new')}>Create New Tag</button>
            <table>
                <thead>
                <tr>
                    <th>Value</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {tags.map(tag => (
                    <tr key={tag.id}>
                        <td>{tag.value}</td>
                        <td>
                            <button onClick={() => navigate(`/admin/tags/edit/${tag.id}`)}>Edit</button>
                            <button onClick={() => deleteTag(tag.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

const TagForm = () => {
    const [tag, setTag] = useState({ value: '' });
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            fetch(`${BASE_URL}/store/blog/tags/${id}`)
                .then(res => res.json())
                .then(data => setTag(data));
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const method = id ? 'PUT' : 'POST';
        const url = id ? `${BASE_URL}/admin/blog/tags/${id}` : `${BASE_URL}/admin/blog/tags`;

        fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(tag)
        }).then(() => navigate('/admin/tags'));
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                value={tag.value}
                onChange={e => setTag({...tag, value: e.target.value})}
                placeholder="Tag Value"
            />
            <button type="submit">{id ? 'Update' : 'Create'} Tag</button>
        </form>
    );
};

// App principale
const App = () => {
    return (
        <div className={"admin-container"}>
            <div style={{display: 'flex', justifyContent: 'center', gap: 8}}>

                <button onClick={() => window.location.href = '/data'}>User data</button>
                <button onClick={() => window.location.href = '/admin/posts'}>Posts</button>
                <button onClick={() => window.location.href = '/admin/categories'}>Categories</button>
                <button onClick={() => window.location.href = '/admin/tags'}>Tags</button>
            </div>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                <Route path="/data" element={<Data />} />
                <Route path="/admin" element={<AdminHome />} />
                <Route path="/admin/posts" element={<AdminPosts />} />
                <Route path="/admin/posts/new" element={<PostForm />} />
                <Route path="/admin/posts/edit/:id" element={<PostForm />} />
                <Route path="/admin/categories" element={<AdminCategories />} />
                <Route path="/admin/categories/new" element={<CategoryForm />} />
                <Route path="/admin/categories/edit/:id" element={<CategoryForm />} />
                <Route path="/admin/tags" element={<AdminTags />} />
                <Route path="/admin/tags/new" element={<TagForm />} />
                <Route path="/admin/tags/edit/:id" element={<TagForm />} />
            </Routes>
        </Router>
        </div>
    );
};

export default App;